// Romanian
export default{
    form: {
        startSurveyButton: "Să începem",
        nextButton: "Următorul",
        submitAnswerButton: "Trimite Răspuns",
        continueButton: "Continuă",
        finishButton: "Finalizează",
        headerPercentageCompletion: 'completat',
        headerQuestionsCompleted: 'întrebări',
        headerDefaultErrorMessage: 'Ups! Ceva a mers greșit.',
        brandingPoweredBy: 'Propulsat de',
        brandingPromoWhySurvey: 'De ce să faci un sondaj când poți folosi <b>{product}</b>?',
        brandingPromoGetStarted: 'Începe azi!',
        submissionPending: "Voiceform-ul tău este trimis. Te rugăm să nu închizi fereastra browserului.",
        submissionCanCloseWindow: "Voiceform-ul tău a fost trimis cu succes. Acum poți închide fereastra 👍",
        mediaAttachmentOverlayCloseButton: "Închide",
        expiredFormHeader: "Mulțumim pentru vizită!",
        expiredFormMessage: "Se pare că acest sondaj a expirat. Te rugăm să contactezi creatorul voiceform-ului pentru a-l informa.",
        notPublishedFormHeader: "Voiceform-ul nu este publicat!",
        notPublishedFormMessage: "Se pare că acest sondaj nu a fost publicat. Te rugăm să contactezi creatorul voiceform-ului pentru a-l informa.",
        notPublishedFormRetryButton: "Încearcă din nou",
        errorFormHeader: "Ups!",
        errorFormMessage: "Ceva a mers greșit. Te rugăm să încerci mai târziu.",
        errorFormRetryButton: "Încearcă din nou",
        emptyFormHeader: "Mulțumim pentru vizită!",
        emptyFormMessage: "Ups! Acest formular pare să fie gol momentan, fără întrebări sau câmpuri de completat. Ne cerem scuze pentru inconveniență. Te rugăm să contactezi creatorul formularului pentru a-l informa despre această problemă. Mulțumim pentru înțelegere și colaborare!",

        restoreFormHeader: "Se pare că ai un formular în progres!",
        restoreFormMessage: "Vrei să continui de unde ai rămas?",
        restoreFormButton: "Continuă Formularul",
        restoreFormButtonNew: "Începe de la capăt",

        audioPermissionHeader: "Vrei să răspunzi prin voce?",
        audioPermissionMessage: "Vorbirea va face răspunsul rapid și ușor.",
        audioPermissionAcceptButton: "Da, vreau",
        audioPermissionDenyButton: "Nu, mulțumesc",
        audioPermissionErrorHeader: "Microfonul nu este disponibil",
        audioPermissionErrorMessage: "Te rugăm să verifici permisiunile browserului pentru a permite accesul la microfon.",
        audioPermissionErrorButton: "Ok",
        audioRecorderFailedUpload: "Eșec la încărcarea înregistrării",
        silenceDetectorError: "Nu te putem auzi. Te rugăm să verifici preferințele microfonului. Sfat: Poate fi microfonul tău extern.",
        audioRecorderButtonRecord: "Înregistrează",
        audioRecorderButtonRecordMore: "Înregistrează mai mult",
        voiceResponseSelectionMessage: "Selectează cum vrei să răspunzi...",
        voiceResponseSelectionOrDivider: "sau",
        voiceResponseTextInputPlaceholder: "Tastează răspunsul",
        voiceResponseTextInputCharacterCounterMinMessage: "Te rugăm să introduci cel puțin {min} caractere",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} caractere (minim {min} caractere)",
        voiceResponseTextInputCharacterValidationMessage: "{count} caractere (minim {min} caractere)",

        // checkbox
        checkboxValidationTooFew: "Trebuie să selectezi cel puțin {min} opțiune(i)",
        checkboxValidationTooMany: "Poți selecta maxim {max} opțiune(i)",
        checkboxNoneOfTheAboveOption: "Niciuna dintre cele de mai sus",
        checkboxInvalidAnswer: "Răspuns invalid.",

        // datepicker
        datePickerPlaceholder: "Selectează data",

        // dropdown
        // email
        emailLabel: "E-mail",
        emailInvalid: "E-mail invalid.",
        // file-upload
        fileUploadPluginNameCamera: "Cameră",
        fileUploadPluginNameCameraVideo: "Înregistrează video",
        fileUploadPluginScreenCast: "Captură de ecran",
        fileUploadVideoImportFilesDefault: 'Înregistrează sau încarcă un video prin:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Selectează o opțiune de înregistrare video:',
        fileUploadVideoImportFilesNoCamera: 'Apasă un buton pentru a înregistra un video pe ecran',
        fileUploadVideoImportFilesNoScreenCast: 'Apasă un buton pentru a înregistra un video',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Nu există opțiuni disponibile pentru a înregistra un video',

        // matrix
        matrixValidationMessage: "Răspuns valid.",
        matrixRow: "Rând",
        matrixColumn: "Coloană",
        matrixRowRequired: "Rândul {rowTitle} este necesar.",
        matrixRadioGroup: "Grup radio",
        matrixCheckboxGroup: "Grup checkbox",
        matrixGroupRequired: "Pentru rândul {rowTitle}. {type} {groupTitle} este necesar.",
        matrixColumnRequired: "Pentru rândul {rowTitle}. Coloana {columnTitle} este necesară.",
        matrixColumnInvalid: "Pentru rândul {rowTitle}. Coloana {columnTitle} este invalidă.",
        matrixRequired: "Necesar.",
        matrixNumericMustBeNumber: "Trebuie să fie un număr.",
        matrixNumericMustBeGreaterThenMin: "Trebuie să fie mai mare decât {min}.",
        matrixNumericMustBeLessThenMax: "Trebuie să fie mai mic decât {max}.",
        matrixNumericMustBeInteger: "Trebuie să fie un număr întreg.",
        matrixNumericInvalidNumber: "Număr invalid.",

        // name
        nameLabel: "Nume",
        nameInvalid: "Te rugăm să specifici numele și prenumele.",

        // nps
        npsNotLikely: "Foarte puțin probabil",
        npsExtremelyLikely: "Foarte probabil",

        // numeric input
        numericInputRequired: "Necesar.",
        numericInputMustBeNumber: "Trebuie să fie un număr.",
        numericInputMustBeGreaterThenMin: "Trebuie să fie mai mare decât {min}.",
        numericInputMustBeLessThenMax: "Trebuie să fie mai mic decât {max}.",
        numericInputMustBeInteger: "Trebuie să fie un număr întreg.",
        numericInputInvalidNumber: "Număr invalid.",
        numericInputPlaceholder: "Tastează numărul",

        // phone
        phoneInvalid: "Număr de telefon invalid.",
        phoneCountrySelectorLabel: 'Codul țării',
        phoneCountrySelectorError: 'Alege țara',
        phoneNumberLabel: 'Număr de telefon',
        phoneExample: 'Exemplu:',

        // boolean
        booleanYesLabel: "Da",
        booleanNoLabel: "Nu",

        //questionStep
        questionStepAudioQuestionLabel: "Întrebare Audio",

        // errors
        invalidPhoneNumber: "{phone} este un număr de telefon invalid.",
        invalidEmail: "{email} este o adresă de e-mail invalidă.",
        questionIsRequired: "Întrebarea este necesară.",
        answerIsNotValid: "Răspunsul nu este valid.",
        unfinishedProbingDialogError: "Te rugăm să finalizezi dialogul.",
        cannotResumePartialResponse: "Nu se poate relua răspunsul parțial.",
        failedToSubmitForm: "Trimiterea formularului a eșuat. Te rugăm să verifici conexiunea la internet și să încerci din nou.",

        //language picker
        searchLanguage: "Caută limbă",
    }
}
